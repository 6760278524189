.team-logo {
  @apply w-full h-full;
  @apply object-contain object-center;
  @apply flex-shrink-0;
  @apply drop-shadow-[0_0_2px_rgba(34,34,34,0.3)];
}
.team-logo-initials {
  @apply w-full h-full;
  @apply flex items-center justify-center;
  @apply text-white;
  @apply flex-shrink-0;
  @apply bg-[url("../assets/icons/klodeckel.svg")];
  @apply bg-contain bg-center bg-no-repeat;
  &.team-logo-initials-sm {
    @apply text-sm;
    @apply w-4 h-4;
  }
  & .team-logo-initial {
    @apply transform -translate-y-0.5;
  }
  &.team-logo-initials-md {
    @apply text-sm;
  }
  &.team-logo-initials-lg {
    @apply text-2xl;
  }
  & .team-logo-initial {
    @apply filter drop-shadow;
  }
}
