.match-team-name {
  @apply inline-flex;
  @apply items-center;
  @apply justify-end;
  @apply flex-1;
  @apply relative;
  z-index: 2;
  @apply cursor-default;
  min-width: 0;

  & .match-team-name-content {
    @apply flex items-center justify-end;
    @apply relative;
    @apply w-full;
    @apply relative;
    @apply mx-3;
    @apply py-2;
    min-width: 0;

    & .match-team-name-bg {
      @apply bg-no-repeat bg-center;
      background-size: 100% 100%;
      @apply absolute top-0 left-0 right-0 bottom-0;

      &:before,
      &:after {
        content: "";
        @apply absolute;
        @apply top-0;
        @apply w-3 h-full;
        @apply bg-no-repeat;
        background-size: 100% 100%;
      }
      &:before {
        @apply bg-right;
        @apply -left-3;
        @apply transform translate-x-px;
      }
      &:after {
        @apply bg-left;
        @apply -right-3;
        @apply transform -translate-x-px;
      }
    }

    & .match-team-name-text {
      @apply truncate;
      z-index: 1;
      @apply text-lg;
      @apply leading-5;
      @apply text-default-color-forced-dark;
    }

    & .match-team-name-text-no-background {
      @apply truncate;
      @apply text-lg;
      @apply leading-5;
      @apply text-default-color;
    }

    & .match-team-name-icon {
      height: 20px;
      width: 20px;
      @apply object-contain;
      @apply ml-1 -mr-1;
      @apply sm:mr-0;
      z-index: 1;
      @apply flex-shrink-0;
      @media (min-width: theme("screens.xs")) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.match-team-name-default {
    & .match-team-name-content {
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
    }
  }

  &.match-team-name-tippable {
    z-index: 4;
    @apply cursor-pointer;
    & .match-team-name-plus {
      @apply -mx-1;
      z-index: 1;
      @apply relative;
    }
  }

  &.match-team-name-right {
    @apply flex-row-reverse;

    & .match-team-name-content {
      @apply flex-row-reverse;

      & .match-team-name-bg {
        transform: scaleX(-1);
      }
      & .match-team-name-icon {
        @apply -ml-1 mr-1;
        @apply sm:ml-0;
      }
    }
  }
}

@each $color in default, correct, incorrect, selected {
  .match-team-name.match-team-name-$(color) {
    & .match-team-name-content {
      & .match-team-name-bg {
        background-image: url("../assets/team-name/team-name-$(color)-middle.svg");
        &:before {
          background-image: url("../assets/team-name/team-name-$(color)-left.svg");
        }
        &:after {
          background-image: url("../assets/team-name/team-name-$(color)-right.svg"); 
        }
        &.match-team-name-bg-isOtherUser {
          background-image: url("../assets/team-name/team-name-$(color)-middle-user.svg");
          &:before {
            background-image: url("../assets/team-name/team-name-$(color)-left-user.svg");
          }
          &:after {
            background-image: url("../assets/team-name/team-name-$(color)-right-user.svg");
          }
        }
      }
    }
  }
  .dark .match-team-name.match-team-name-$(color) {
    & .match-team-name-content {
      & .match-team-name-bg {
        background-image: url("../assets/team-name/team-name-$(color)-middle-dark.svg");
        &:before {
          background-image: url("../assets/team-name/team-name-$(color)-left-dark.svg");
        }
        &:after {
          background-image: url("../assets/team-name/team-name-$(color)-right-dark.svg");
        }
        &.match-team-name-bg-isOtherUser {
          background-image: url("../assets/team-name/team-name-$(color)-middle-user-dark.svg");
          &:before {
            background-image: url("../assets/team-name/team-name-$(color)-left-user-dark.svg");
          }
          &:after {
            background-image: url("../assets/team-name/team-name-$(color)-right-user-dark.svg");
          }
        }
      }
    }
  }
}
