.btn {
  @apply py-1 px-5;
  @apply rounded;
  @apply m-px;
  @apply flex items-center justify-center;
  @apply text-center;
  @apply uppercase;
  @apply text-sm;
  @apply cursor-pointer;
  @apply select-none;
  @apply border border-transparent;
  @apply w-full sm:w-fit;
  @screen md {
    @apply py-1 px-10;
  }
  & svg {
    @apply w-5 h-5;
  }
  &.btn-primary-outline {
    @apply border border-primary dark:border-secondary rounded;
    @apply transition-shadow duration-200;
    @apply hover:shadow;
    @apply text-default-color;
  }
  &.btn-white-outline {
    @apply border border-white rounded;
    @apply transition-shadow duration-200;
    @apply hover:shadow;
    @apply text-default-color;
  }
  &.btn-kicker-outline {
    @apply border border-k-red-100 rounded;
    @apply transition-shadow duration-200;
    @apply hover:shadow;
    @apply text-default-color;
  }
  &.btn-primary {
    @apply bg-primary;
    @apply border border-secondary;
    @apply text-white;
    @apply rounded;
    @apply transition-colors duration-300 hover:bg-secondary;
  }
  &.btn-danger {
    @apply text-status-negative-red200 dark:text-status-negative-red100;
    @apply border-status-negative-red200 dark:border-status-negative-red100;
  }
  &.btn-xs {
    @apply text-xs;
  }
  &.btn-sm {
    @apply text-sm;
  }
  &.btn-lg {
    @apply text-lg;
    @apply py-3 px-10;
  }
  &:disabled {
    @apply bg-gray-200 hover:bg-gray-200;
    @apply text-white hover:text-white;
    @apply border-gray-200;
    @apply cursor-not-allowed;
  }
}

.link-button {
  @apply flex;
  @apply w-full sm:w-fit;
  & .btn {
    @apply w-full;
  }
}

.btn-wrapper {
  @apply flex flex-wrap justify-center;
  &:not([data-amount="1"]) {
    @apply gap-4;
    & > .btn,
    & > .link-button {
      @apply md:w-[47%] w-full;
    }
    & .btn {
      @apply px-2 sm:px-5 md:px-10;
    }
  }
  &.btn-wrapper-list {
    @apply flex-col;
    @apply space-y-2;

    & > .btn,
    & > .link-button {
      @apply w-full;
    }
  }
}

.next-button {
  @apply flex items-center justify-between;
  @apply w-full;
  @apply border-b border-gray-100;
  @apply last:border-none;
  @apply pb-4 mb-4;
  @apply cursor-pointer;
  & .next-button-text {
    @apply truncate;
    @apply flex-1;
    @apply text-lg;
    @apply pr-4;
  }
  & .next-button-icon-wrapper {
    @apply flex-shrink-0;
    @apply w-4 h-4;
    @apply flex items-center justify-center;
    & .next-button-icon {
      @apply transition-transform duration-300;
      @apply fill-current;
    }
  }
  &:hover {
    & .next-button-icon-wrapper {
      & .next-button-icon {
        @apply transform -translate-x-1/2;
      }
    }
  }
}

.btn-container {
  @apply flex flex-col items-center justify-center space-y-2;
}
