.date-header {
  @apply mx-auto mb-2;
  @apply py-2;
  @apply text-center text-primary uppercase;
  @apply relative;
  @apply flex items-center justify-center space-x-2;

  & .date-header-logo {
    width: 22px;
    height: 22px;
    @apply object-contain;
  }

  &:before {
    content: "";
    @apply absolute bottom-0 left-4 right-4;
    @apply h-px;
    @apply bg-primary;
  }
}
