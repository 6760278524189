.badge-user {
  @apply w-24 h-24;
  @apply inline-flex justify-center;
  @apply relative;
  @apply bg-[url('../assets/top-three/user-default.svg')] bg-no-repeat bg-bottom bg-contain;
  & .badge-user-user-image {
    @apply rounded-full;
    @apply w-14 h-14;
    @apply mb-1 -mt-2;
    @apply border border-user-default;
    border-width: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    & .user-image-wrapper {
      @apply w-full h-full;
      @apply border-2 border-user-default-alternative;
      @apply rounded-full;
      & .user-image {
        @apply w-full h-full object-cover object-center;
      }
    }
  }
}
