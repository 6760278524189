.sports-type-header {
  @apply w-full h-full;
  @apply flex items-center justify-between;
  @apply border-b border-gray-200;
  @apply pb-1 mt-2 mb-2;
  @apply first:mt-0;

  & .sports-type-image {
    @apply object-contain object-center;
    @apply flex-shrink-0;
    padding: 2px;
    filter: theme("filter.tint-gray-200");
    @apply w-5 h-5;
  }

  & .sports-type-image-placeholder {
    @apply w-5 h-5;
  }

  & .sports-type-text {
    @apply text-sm font-normal;
    @apply text-gray-200;
    @apply truncate;
    @apply flex-1;
    @apply px-1;
  }
}
