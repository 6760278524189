.match-detail {
  @apply text-center;
  & .match-row {
    &.match-row-has-label {
      @apply mt-1;
    }
    &.match-row-detail {
      & .match-team-name {
        & .match-team-name-content {
          & .match-team-name-text {
            @apply text-default-color;
          }
        }
      }
    }
  }

  & .match-row-items {
    @apply px-0;
  }

  & .match-detail-tendency-results {
    @apply border-2;
    @apply border-secondary;
    @apply rounded;
    @apply tracking-widest;
    @apply px-2.5;
    @apply py-px;
    @apply text-lg;
  }

  & .last-three-circle {
    @apply rounded-full;
    @apply w-5 h-5;
    @apply mx-0.5;
    @apply inline-block;
    @apply text-center;
    @apply text-white;
    @apply text-sm;
    @apply font-normal;
  }

  & .vertical-divider {
    @apply h-2 sm:h-2;
    @apply w-0.5 sm:w-0.5md;
    @apply bg-secondary;
    margin: 0.5rem auto 0;
  }

  & .row-detail-tendency {
    & span {
      @apply relative;
      @apply inline-flex items-center;
      &:after {
        content: "%";
        @apply absolute right-0;
        @apply transform translate-x-full;
      }
    }
  }

  & .row-detail-tendency-0 {
    & .match-detail-tendency-result-label {
      @apply h-3;
      @apply relative;
      @apply mt-1;

      &:before {
        content: "";
        @apply absolute;
        @apply top-0;
        @apply h-3;
        @apply w-11/12 md:w-full;
        background: no-repeat right / contain
          url("../assets/label/tipp-lable-tendency-left.svg");
        @apply left-2/4;
        background-size: 100% auto;
        max-width: 130px;
      }
    }
  }

  & .row-detail-tendency-1 {
    & .match-detail-tendency-result-label {
      @apply h-3;
      @apply w-0.5 sm:w-0.5md;
      @apply bg-secondary;
      margin: 0.25rem auto 0;
    }
  }

  & .row-detail-tendency-2 {
    & .match-detail-tendency-result-label {
      @apply h-3;
      @apply relative;
      @apply mt-1;

      &:before {
        content: "";
        @apply absolute;
        @apply top-0;
        @apply h-3;
        @apply w-11/12 md:w-full;
        background: no-repeat right / contain
          url("../assets/label/tipp-lable-tendency-right.svg");
        @apply right-2/4;
        background-size: 100% auto;
        max-width: 130px;
      }
    }
  }

  & .match-detail-tipp-overlay {
    & .match-team-name {
      @apply py-5;
      @apply justify-center;
      @apply bg-no-repeat bg-center bg-contain;
      &.match-team-name-left {
        @apply bg-[url("../assets/team-name/plusminusbox-left.svg")];
        @apply dark:bg-[url("../assets/team-name/plusminusbox-left-dark.svg")];
      }
      &.match-team-name-right {
        @apply bg-[url("../assets/team-name/plusminusbox-right.svg")];
        @apply dark:bg-[url("../assets/team-name/plusminusbox-right-dark.svg")];
      }

      & .match-team-name-content {
        @apply justify-center;
        @apply py-1;

        & .match-team-name-icon {
          @apply m-0;
          @apply w-8 h-8;
        }
      }

      & .match-team-name-btn {
        @apply absolute;
        @apply w-6/12 sm:w-2/6;
        @apply -z-10;
        @apply text-1.5xl;
        @apply text-secondary dark:text-white;
        @apply font-normal;

        &.match-team-name-plus {
          top: -0.3rem;
        }

        &.match-team-name-minus {
          bottom: -0.3rem;
        }
      }
    }

    & .match-result-tippable {
      & .match-result-tippable-label {
        @apply mx-2;
        z-index: 5;
        &:before,
        &:after {
          @apply w-4;
        }
        &:before {
          @apply transform translate-x-0.5;
          @apply -left-4;
        }
        &:after {
          @apply transform -translate-x-0.5;
          @apply -right-4;
        }
        & .label-content {
          & .match-result-number,
          & .match-result-divider {
            @apply text-2xl;
            color: #4161a7;
          }
        }
      }
    }
  }
}

.user-tips {
  @apply flex items-stretch space-x-2;
  background-color: #f0f2f4;
  @apply dark:bg-background;
  @apply p-2;
  @apply my-4;
  @apply rounded;
  @apply font-normal;
  & .user-tips-images {
    @apply flex -space-x-2;
    @apply py-1;
  }
  & .user-tips-text {
    @apply text-left;
  }
}
