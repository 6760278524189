.profile-tendency {
  @apply flex items-center;
  @apply w-full;
  @apply py-2;
  & .profile-tendency-label {
    @apply flex-1;
    @apply h-3;
    @apply relative;
    &:before,
    &:after {
      content: "";
      @apply absolute top-0;
      @apply inline-block;
      @apply bg-no-repeat bg-center bg-contain;
      @apply h-3 w-3;
    }
    &.profile-tendency-label-start {
      @apply mx-4;
      @apply bg-repeat-x bg-left;
      @apply bg-contain bg-[url("../assets/tendency/tipp-label-tendency-left-center.svg")];
      @apply dark:bg-[url("../assets/tendency/tipp-label-tendency-left-center-dark.svg")];
      &:before {
        @apply -left-3;
        @apply bg-contain bg-[url("../assets/tendency/tipp-label-tendency-left-start.svg")];
        @apply dark:bg-[url("../assets/tendency/tipp-label-tendency-left-start-dark.svg")];
      }
      &:after {
        @apply -right-3;
        @apply bg-contain bg-[url("../assets/tendency/tipp-label-tendency-left-end.svg")];
        @apply dark:bg-[url("../assets/tendency/tipp-label-tendency-left-end-dark.svg")];
      }
    }
    &.profile-tendency-label-end {
      @apply mx-3;
      @apply bg-contain bg-[url("../assets/tendency/tipp-label-tendency-right-center.svg")];
      @apply dark:bg-[url("../assets/tendency/tipp-label-tendency-right-center-dark.svg")];
      &:before {
        @apply -left-3;
        @apply bg-contain bg-[url("../assets/tendency/tipp-label-tendency-right-start.svg")];
        @apply dark:bg-[url("../assets/tendency/tipp-label-tendency-right-start-dark.svg")];
      }
      &:after {
        @apply -right-3;
        @apply bg-contain bg-[url("../assets/tendency/tipp-label-tendency-right-end.svg")];
        @apply dark:bg-[url("../assets/tendency/tipp-label-tendency-right-end-dark.svg")];
      }
    }
  }
}
