.tip-group-image-wrapper {
  @apply rounded;
  @apply text-white;
  @apply flex items-center justify-center;
  @apply relative;
  @apply w-full h-full;
  @apply overflow-hidden;
  @apply flex-shrink-0;
  @apply place-self-center;
  & img {
    @apply rounded;
  }
  &.tip-group-image-wrapper-sm {
    @apply w-5 h-5;
    @apply text-sm;
  }
  &.tip-group-image-wrapper-md {
    @apply w-6 h-6;
    @apply text-base;
  }
  &.tip-group-image-wrapper-lg {
    @apply w-8 h-8;
    @apply text-xl;
  }
  & .tip-group-image-initials {
    @apply filter drop-shadow;
  }
  & .tip-group-image {
    @apply w-full h-full;
    @apply object-cover object-center;
  }
}
