.gameday {
  @apply mx-auto;
  @apply w-full;
  @apply flex flex-col;
  @apply items-center;
  @apply p-4;
}

.gameday-chooser-header {
  @apply flex justify-between items-center;
  @apply pt-3 mb-4;
  @apply mx-auto;
  @apply w-full max-w-screen-sm;

  & .gameday-chooser-header-empty {
    @apply w-6 h-6;
    @apply mx-2;
  }

  & .gameday-chooser-header-left,
  & .gameday-chooser-header-right {
    @apply w-6 h-6;
    @apply bg-contain bg-no-repeat bg-center;
    @apply mx-2;
    @apply relative;
    @apply cursor-pointer;

    &:before {
      content: "";
      @apply w-px h-full;
      @apply absolute top-0 bottom-0;
      @apply bg-primary dark:bg-secondary;
    }
  }

  & .gameday-chooser-header-left {
    @apply bg-[url("../assets/icons/arrow-left.svg")];
    @apply dark:bg-[url("../assets/icons/arrow-left-dark.svg")];

    &:before {
      @apply -right-2;
    }
  }

  & .gameday-chooser-header-right {
    @apply bg-[url("../assets/icons/arrow-right.svg")];
    @apply dark:bg-[url("../assets/icons/arrow-right-dark.svg")];

    &:before {
      @apply -left-2;
    }
  }

  & .gameday-chooser-header-center {
    @apply text-primary;
  }
}
