table.kicker-tippspiel-table {
  @apply w-full;
  @apply border-collapse;
  @apply mb-4;

  & td,
  & th {
    @apply py-1 px-1;
    @apply whitespace-nowrap;
  }

  & tr {
    @apply transform -translate-y-px; /* HIDE BOTTOM BORDER OF PREVIOUS ROW ON SELECTED ROW */
    & th {
      @apply border-b border-primary;
      @apply text-sm;
    }
    & td {
      @apply py-2;
      border-bottom: 1px solid rgba(var(--primary-rgb), 0.06);
      & .label {
        @apply w-full;
        & .label-content {
          @apply px-px py-1;
          @apply leading-none;
          line-height: 19px;
        }
      }
    }
    &:last-child {
      & td {
        @apply border-none;
      }
    }
    &.row-selected {
      td {
        @apply bg-primary;
        @apply text-white;
        @apply border-none;
        &:first-child,
        &:last-child {
          @apply relative;
          &:before,
          &:after {
            @apply h-full w-1;
            @apply bg-primary;
            @apply absolute top-0;
          }
        }
        &:first-child {
          &:before {
            content: "";
            @apply rounded-l;
            @apply -left-1;
            @apply transform translate-x-px;
          }
        }
        &:last-child {
          &:before {
            content: "";
            @apply rounded-r;
            @apply -right-1;
            @apply transform -translate-x-px;
          }
        }
      }
    }
  }
  &.table-minimal {
    & tr {
      & th {
        @apply border-none;
        @apply py-1;
      }
      & td {
        @apply py-1;
      }
    }
  }
}
