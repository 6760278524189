@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "base";
@import "fonts";
@import "theme";
@import "league";
@import "gameday";
@import "match/match";
@import "card";
@import "loading";
@import "dropdown";
@import "label";
@import "table";
@import "ranking";
@import "button";
@import "tip-group";
@import "list";
@import "header";
@import "navigation";
@import "news";
@import "forms";
@import "sticky-footer";
@import "user-image";
@import "league-image";
@import "tip-group-image";
@import "team-logo";
@import "oddsServe";
@import "date-header";
@import "section-header";
@import "badge-user";
@import "profile";
@import "e2";
@import "sports-type";
