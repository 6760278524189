.custom-list {
  @apply flex flex-col;

  & .custom-list-row {
    @apply flex items-center justify-between;
    @apply mb-3;

    &.custom-list-row-headline {
      @apply text-lg font-bold;
      @apply mb-0;
      &.custom-list-row-headline-border {
        @apply border-b border-default-color;
        @apply pb-2 mb-4;
      }
    }

    &.custom-list-row-headline-light {
      @apply text-lg font-light;
      @apply mb-0;
      @apply first:mt-0;
      &.custom-list-row-headline-border {
        @apply border-b border-default-color;
        @apply pb-2 mt-2 mb-4;
      }
    }

    &.custom-list-row-headline-small {
      @apply text-sm font-normal;
      @apply text-gray-200;
      @apply mb-0;
      @apply first:mt-0;
      &.custom-list-row-headline-border {
        @apply border-b border-gray-200;
        @apply pb-1 mt-2 mb-2;
        @apply first:mt-0;
      }
    }

    & .custom-list-item {
      @apply whitespace-nowrap;
      @apply flex-shrink-0;
      &:nth-child(2) {
        @apply truncate;
        @apply flex-1;
        @apply px-4;
      }
    }

    & .custom-list-item-no-padding {
      @apply whitespace-nowrap;
      @apply flex-shrink-0;
      &:nth-child(2) {
        @apply truncate;
        @apply flex-1;
        @apply pr-4;
      }
    }
  }
}
