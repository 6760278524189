.nav-mobile-backdrop {
  @apply fixed top-0 right-0 left-0 bottom-0;
  background-color: rgba(19, 20, 21, 0.95);
  @apply hidden;
  @apply z-50;
  &[data-open="true"] {
    @apply block;
    animation: fadeIn 0.5s ease-in-out;
  }
}

.nav-mobile-backdrop-web {
  @apply absolute;
}

.nav-mobile {
  @apply fixed top-0 -right-full bottom-0;
  @apply bg-surface;
  @apply w-4/5;
  max-width: 294px;
  @apply transition-all duration-500;
  @apply z-50;
  @apply flex flex-col;

  &[data-open="true"] {
    @apply right-0;
  }
  & .nav-title {
    @apply px-3 py-2;
    @apply bg-primary;
    @apply text-1.5xl text-white;
    @apply flex items-center justify-between space-x-2;
    & .nav-title-icon {
      @apply w-10 h-10;
      @apply object-contain;
      & svg {
        @apply max-w-full max-h-full w-auto h-auto;
      }
    }
    & .nav-title-title {
      @apply flex-1;
    }
    & .nav-close {
      @apply w-6 h-6;
      @apply flex items-center justify-center;
      @apply relative;
      & .nav-close-action {
        @apply absolute;
        @apply w-12 h-12;
        @apply cursor-pointer;
        @apply flex items-center justify-center;
      }
    }
  }
  & .nav-groups {
    @apply overflow-y-auto;
    @apply flex-1;

    & .nav-group {
      @apply px-7;
      @apply mt-4 mb-8;

      & .nav-group-title {
        @apply -ml-2;
        @apply text-lg;
        & .label-content {
          @apply py-1.5;
        }
      }
      & .nav-group-section {
        @apply my-2;
      }
      & .nav-group-item {
        @apply text-xl leading-6;
        @apply my-4;
        @apply flex items-start space-x-2;
        @apply cursor-pointer;

        & .nav-group-item-icon-wrapper {
          @apply w-5 h-5;
          @apply flex items-center justify-center;
          @apply shrink-0;
          @apply transform translate-y-0.5;
          & img {
            @apply w-full h-auto;
            @apply object-contain;
          }
        }
        & .nav-group-item-text {
          @apply line-clamp-2;
        }
      }
    }
  }
}

.nav-web {
  @apply absolute;
}

@keyframes fadeIn {
  0% {
    @apply block;
    @apply opacity-0;
  }
  100% {
    @apply opacity-100;
  }
}
