.sticky-footer {
  @apply sticky;
  @apply bottom-0 left-0 right-0;
  @apply p-4;
  @apply flex items-center justify-center;
  @apply z-10;
  &.sticky-footer-background {
    @apply bg-surface;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  }
}
