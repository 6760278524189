input.input[type="text"],
textarea.input {
  @apply px-4 py-3.5;
  @apply border border-gray-200;
  @apply rounded;
  @apply outline-none;
  @apply dark:bg-background;
  line-height: 19px;
  resize: none;
  @apply w-full max-w-sm;
  &::placeholder {
    @apply text-gray-200;
  }
}
