.match-row {
  @apply py-1;
  @apply my-4;

  &.match-row-has-label {
    @apply mt-4;
  }

  &.match-row-tippable {
    & .match-result {
      @apply mx-0;
    }
    & .match-row-items {
      @apply px-0;
    }
  }

  &.match-row-opacity-low {
    & .match-row-items {
      @apply opacity-70;
    }
  }

  & .match-row-items {
    @apply flex;
    @apply items-center justify-center;
    @apply w-full;
    @apply sm:relative;
    @apply px-7;
    @apply sm:px-17;

    & .match-row-points-label {
      @apply sm:absolute sm:right-0;
    }
  }
  & .match-row-label {
    @apply flex justify-center;
    & .label {
      @apply -mb-2 mx-auto;
      @apply z-20;
    }
  }

  & .match-time-decent {
    @apply py-0.5;
    @apply px-2;
    @apply border-solid;
    @apply border-2;
    @apply border-gray-100 dark:border-gray-200;
    @apply rounded;
    @apply font-normal;
    @apply tracking-wider;
  }
}
