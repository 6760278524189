.league-image {
  @apply w-full h-full;
  @apply object-contain object-center;
  @apply flex-shrink-0;

  &.league-image-sm {
    @apply w-4 h-4;
  }
  &.league-image-md {
    @apply w-6 h-6;
  }
  &.league-image-lg {
    @apply w-16 h-16;
  }
}
.league-image-initials {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  @apply inline-flex items-center justify-center;
  @apply text-white;
  @apply flex-shrink-0;
  &.league-image-initials-sm {
    @apply text-xs;
    @apply w-4 h-4;
  }
  &.league-image-initials-md {
    @apply text-xs;
    @apply w-6 h-6;
  }
  &.league-image-initials-lg {
    @apply text-2xl;
    @apply w-16 h-16;
  }
  & .league-image-initial {
    @apply filter drop-shadow;
  }
}
