.match-result {
  @apply -mx-1;
  @apply rounded-md;
  @apply bg-dark;
  @apply text-white;
  z-index: 3;
  @apply text-right;
  @apply inline-flex justify-center items-center;
  @apply relative;
  @apply border-2 border-gray-200;
  @apply p-0;

  & .match-result-label {
    @apply absolute -top-0;
    @apply transform -translate-y-full;
    @apply m-0;
    @apply mt-px; /* label is supposed to be 3px into the result-box, but apparently the 2px border of the box doesn't count when positioning the label, so i only use 1px which adds up to 3px with the border (Flo) */
    @apply z-20;

    &.match-result-label-tip,
    &.match-result-label-no-tip {
      & .label-content {
        @apply px-0;
        z-index: 3;
        @apply space-x-0;
        & .match-result-label-tip-number {
          width: 2ch;
          @apply text-center;
        }
        & .match-result-label-tip-divider {
          width: 1ch;
        }
      }
    }
  }
  &.match-result-live {
    @apply text-live;
  }
  &.match-result-green {
    @apply border-status-positive-green100;
  }
  &.match-result-red {
    @apply border-status-negative-red100;
  }
}

.match-result,
.match-result-tippable {
  & .match-result-number {
    @apply inline-block;
    @apply bg-none;
    @apply bg-transparent;
    @apply text-center;
    width: 2ch;
    @apply text-xl xs:text-1.6xl;
    line-height: 25px;
    padding: 2px 0;
    /* @apply transform -translate-y-px; */
    @apply font-normal;
    &.match-result-number-basketball {
      @apply text-lg xs:text-xl;
      width: 2.7ch;
    }
  }
  & .match-result-divider {
    /*@apply transform -translate-y-px; */
    @apply text-xl xs:text-2xl;
    line-height: 25px;
    &.match-result-divider-basketball {
      @apply text-lg xs:text-xl;
    }
  }
}

.match-result-tippable {
  & .match-result-number {
    @apply text-xl;
    @apply leading-6;
    @apply tabular-nums;
    z-index: 2;
    @apply first:-ml-1 last:-mr-1;
  }
}
