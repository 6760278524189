@font-face {
  font-family: "Ringside Condensed";
  src: url("../fonts/ringside-condensed/RingsideCondensed-Book.eot");
  src: url("../fonts/ringside-condensed/RingsideCondensed-Book.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ringside-condensed/RingsideCondensed-Book.woff2")
      format("woff2"),
    url("../fonts/ringside-condensed/RingsideCondensed-Book.woff")
      format("woff"),
    url("../fonts/ringside-condensed/RingsideCondensed-Book.ttf")
      format("truetype"),
    url("../fonts/ringside-condensed/RingsideCondensed-Book.svg#RingsideCondensed-Book")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ringside Condensed";
  src: url("../fonts/ringside-condensed/RingsideCondensed-Semi.eot");
  src: url("../fonts/ringside-condensed/RingsideCondensed-Semi.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ringside-condensed/RingsideCondensed-Semi.woff2")
      format("woff2"),
    url("../fonts/ringside-condensed/RingsideCondensed-Semi.woff")
      format("woff"),
    url("../fonts/ringside-condensed/RingsideCondensed-Semi.ttf")
      format("truetype"),
    url("../fonts/ringside-condensed/RingsideCondensed-Semi.svg#RingsideCondensed-Semi")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ringside Condensed";
  src: url("../fonts/ringside-condensed/RingsideCondensed-Bold.eot");
  src: url("../fonts/ringside-condensed/RingsideCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/ringside-condensed/RingsideCondensed-Bold.woff2")
      format("woff2"),
    url("../fonts/ringside-condensed/RingsideCondensed-Bold.woff")
      format("woff"),
    url("../fonts/ringside-condensed/RingsideCondensed-Bold.ttf")
      format("truetype"),
    url("../fonts/ringside-condensed/RingsideCondensed-Bold.svg#RingsideCondensed-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Knockout";
  src: url("../fonts/knockout/Knockout-HTF50-Welterweight.eot");
  src: url("../fonts/knockout/Knockout-HTF50-Welterweight.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/knockout/Knockout-HTF50-Welterweight.woff2") format("woff2"),
    url("../fonts/knockout/Knockout-HTF50-Welterweight.woff") format("woff"),
    url("../fonts/knockout/Knockout-HTF50-Welterweight.ttf") format("truetype"),
    url("../fonts/knockout/Knockout-HTF50-Welterweight.svg#Knockout-HTF50-Welterweight")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
