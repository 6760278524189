.match-result-x {
  @apply w-16 h-10;
  @apply inline-flex justify-center items-center;
  @apply relative;
  @apply -mx-1;

  & .match-result-x-img {
    @apply relative;
    z-index: 3;
    @apply w-3 h-3;
    background: no-repeat center / contain url(../assets/x.svg);
  }

  &:before {
    content: "";
    @apply h-0.5 w-full;
    @apply bg-primary-muted dark:bg-secondary bg-opacity-50;
    @apply absolute;
  }

  &:after {
    content: "";
    @apply absolute;
    @apply bg-no-repeat bg-center bg-contain;
    @apply bg-[url("../assets/octagon-default.svg")];
    @apply w-10 h-10;
    @apply drop-shadow-md;
  }
  &.match-result-x-selected {
    &:after {
      @apply bg-[url("../assets/octagon-selected.svg")];
      @apply dark:bg-[url("../assets/octagon-selected-dark.svg")];
    }
  }
}
