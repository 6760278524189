.kicker-tippspiel {
  --background: var(--gray-100);
  --surface: theme("colors.white");

  --primary: #172032;
  --primary-rgb: 23, 32, 50;
  --primary-muted: #4161a7;
  --secondary: #4161a7;
  --secondary-rgb: 65, 97, 167;

  --gray-100: #f3f3f3;
  --gray-200: #747577;
  --gray-900: #070707;

  --k-green-100: #36a024;
  --k-red-100: #cc0000;

  --default-color: #000;
  --default-color-forced-dark: #000;

  --link: #5585ee;

  --live: #fee733;

  --status-positive-green-100: #0cb332;
  --status-positive-green-200: #1f9c3a;
  --status-positive-green-300: #1e9f3b;
  --status-positive-green-400: #14832d;

  --status-negative-red-100: #e42727;
  --status-negative-red-200: #bd1414;
  &.dark {
    --surface: #1e1e1e;

    --primary: #435c92;
    --primary-rgb: 67, 92, 146;
    --primary-muted: #4161a7;
    --secondary: #749fff;
    --secondary-rgb: 116, 159, 255;

    --gray-100: #121212;
    --gray-200: #747577;
    --gray-900: #fbfbfb;

    --k-green-100: #36a024;
    --k-red-100: #cc0000;

    --default-color: #fbfbfb;

    --link: #5585ee;

    --live: #fee733;

    --status-positive-green-100: #2bc64c;
    --status-positive-green-200: #1fab3e;
    --status-positive-green-300: #18a237;
    --status-positive-green-400: #1cb83f;

    --status-negative-red-100: #e01111;
    --status-negative-red-200: #aa0909;
  }
}
