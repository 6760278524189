.match-result-tippable {
  @apply -mx-1;
  @apply flex;
  & .match-result-tippable-label {
    @apply tabular-nums;
    @apply mx-3.5;
    & .label-content {
      @apply px-0;

      & .match-result-number,
      & .match-result-divider {
        @apply text-lg;
      }
    }
  }
}
