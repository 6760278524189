html,
body {
  @apply h-full;
  @apply p-0 m-0 box-border;
}

.kicker-tippspiel {
  @apply font-ringside;
  font-weight: 600;
  @apply bg-background;
  @apply text-default-color;
  @apply select-none;
  @apply outline-none;
  @apply min-h-full;
}

a.link,
.fake-link {
  @apply text-link;
  @apply cursor-pointer;
  @apply select-text;
}

.kicker-tippspiel-container {
  @apply flex flex-col;
  @apply min-h-screen;
}

.kicker-tippspiel-container-web {
  @apply flex flex-col;
  @apply relative;
  @apply min-h-screen;
}
