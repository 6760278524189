.user-image-wrapper:not(.user-image-custom) {
  @apply rounded-full;
  @apply bg-gray-200;
  @apply text-white;
  @apply flex items-center justify-center;
  @apply relative;
  @apply w-full h-full;
  @apply overflow-hidden;
  &.user-image-wrapper-sm {
    @apply w-4 h-4;
    @apply text-sm;
  }
  &.user-image-wrapper-md {
    @apply w-6 h-6;
    @apply text-base;
  }
  &.user-image-wrapper-lg {
    @apply w-14 h-14;
    @apply text-2xl;
  }
  & .user-image-initials {
    @apply filter drop-shadow;
  }
  & .user-image {
    @apply w-full h-full;
    @apply object-cover object-center;
  }
}
