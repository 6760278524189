.newsIframe {
  overflow: hidden;
  border: 0;
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.top-news {
  @apply mb-6;
  & .top-news-image-wrapper {
    @apply -ml-2 -mr-2;
    @apply aspect-w-16 aspect-h-9;
    & .top-news-image {
    }
  }
  & .top-news-title {
    @apply pt-3 px-2;
    @apply -mt-4;
    @apply font-knockout font-normal;
    @apply text-xl;
    line-height: 22px;
    letter-spacing: 0.45px;
    @apply bg-surface;
    z-index: 2;
    @apply relative;
    @apply rounded-t;
  }
}

.news-entry {
  @apply flex items-start;
  @apply mb-4;
  & .news-entry-image-wrapper {
    @apply w-24 h-24;
    @apply overflow-hidden;
    @apply rounded;
    @apply shrink-0;
    & .news-entry-image {
      @apply w-full h-full;
      @apply object-cover;
      @apply object-center;
    }
  }
  & .news-entry-text {
    @apply shrink-0;
    @apply flex-1;
    @apply pt-0.5 pl-2;
    & .news-entry-title {
      @apply font-knockout font-normal;
      @apply text-xl;
      line-height: 22px;
      letter-spacing: 0.45px;
      @apply line-clamp-2;
    }
    & .news-entry-subline {
      @apply font-normal;
      line-height: 18px;
      @apply text-gray-200;
      @apply line-clamp-2;
      @apply mb-1;
    }
  }
}
