.oddsServe,
.oddsServe.d {
  @apply table;
  @apply w-full max-w-full;
  @apply mt-2;
}

.match-row-time {
  @apply text-link;
  @apply absolute left-1;
}

.oddsServe-headline {
  @apply block;
  @apply px-4;
}
