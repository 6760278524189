.dropdown {
  @apply relative;
  @apply inline-block;
  @apply text-left;

  & .dropdown-button {
    @apply inline-flex justify-center items-center space-x-2.5;
    @apply w-full;
    min-height: 20px;
    @apply text-secondary text-lg font-bold;
    @apply outline-none;
    & .dropdown-button-chevron {
      @apply transition-transform duration-300 translate-y-px;
    }
    &[aria-expanded="true"] {
      & .dropdown-button-chevron {
        @apply transform rotate-180;
      }
    }
  }

  & .dropdown-items {
    @apply absolute;
    @apply left-1/2;
    @apply transform -translate-x-1/2;
    @apply max-h-96 w-52;
    @apply mt-2;
    @apply py-2;
    @apply text-center;
    @apply z-20;
    @apply shadow-xl;
    @apply bg-surface;
    @apply overflow-y-auto;
    @apply rounded;
    @apply outline-none;

    & .dropdown-item {
      @apply block;
      @apply px-8 py-2;
      @apply text-default-color text-sm whitespace-nowrap;
      @apply cursor-pointer;
      @apply transition-colors duration-300 hover:bg-gray-100;

      &.dropdown-item-active {
        @apply bg-gray-100;
        @apply text-primary dark:text-secondary;
        @apply cursor-auto;
        @apply pointer-events-none;
      }
    }
  }

  &.dropdown-size-small {
    & .dropdown-items {
      @apply max-h-24 w-auto;
      @apply p-0;
      box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
      & .dropdown-item {
        @apply px-3;
        @apply text-base;
      }
    }
  }

  &.dropdown-side-right {
    & .dropdown-items {
      @apply left-auto right-0;
      @apply transform-none;
      @apply text-left;
      & .dropdown-item {
        @apply flex items-center space-x-2;
        @apply hover:bg-opacity-20;
      }
    }
  }
}
