.label {
  @apply relative;
  @apply mx-auto;
  @apply w-fit;
  @apply mx-2;
  @apply inline-flex;
  &:not(.match-result-tippable-label) {
    & .label-content {
      @apply space-x-2;
    }
  }

  & .label-content {
    @apply bg-repeat bg-center bg-[url("../assets/label/tipp-label-result-input-middle.svg")];
    background-size: auto 100%;
    @apply px-1;
    @apply text-primary text-center font-bold;
    @apply w-full;
    @apply flex items-center justify-center;

    & span {
      @apply truncate;
      @apply font-semibold;
    }

    & img {
      @apply w-auto h-4;
    }
  }

  &.label-normal {
    --label-spacing: 10px;
    & .label-content {
      @apply text-lg;
      @apply py-1.5;
    }
    &:before,
    &:after {
      @apply w-[var(--label-spacing)];
    }
    &:before {
      @apply -left-[var(--label-spacing)];
    }
    &:after {
      @apply -right-[var(--label-spacing)];
    }
  }

  &.label-medium {
    --label-spacing: 8px;
    & .label-content {
      @apply text-base;
      @apply py-0.5;
    }
    &:before,
    &:after {
      @apply w-[var(--label-spacing)];
    }
    &:before {
      @apply -left-[var(--label-spacing)];
    }
    &:after {
      @apply -right-[var(--label-spacing)];
    }
  }

  &.label-small {
    --label-spacing: 8px;
    @apply mx-[4px];
    @apply text-xs;
    & .label-content {
      @apply px-0.5;
      @apply py-px;
    }

    &:before,
    &:after {
      @apply w-[var(--label-spacing)];
    }
    &:before {
      @apply -left-[var(--label-spacing)];
    }
    &:after {
      @apply -right-[var(--label-spacing)];
    }
  }

  &:before,
  &:after {
    content: "";
    @apply absolute;
    @apply top-0;
    @apply h-full;
    background-size: 100% 100%
  }

  &:before {
    @apply bg-no-repeat bg-right bg-[url("../assets/label/tipp-label-result-input-left.svg")];
      ;
    @apply transform translate-x-[2px];
  }

  &:after {
    @apply bg-no-repeat bg-left;
    @apply bg-[url("../assets/label/tipp-label-result-input-right.svg")];
    @apply transform -translate-x-[2px];
  }

  &.label-correct-difference {
    & .label-content {
      @apply bg-[url("../assets/label/tipp-label-result-correct-difference-middle.svg")];
      @apply text-status-positive-green100;
    }

    &:before {
      @apply bg-[url("../assets/label/tipp-label-result-correct-difference-left.svg")];
    }

    &:after {
      @apply bg-[url("../assets/label/tipp-label-result-correct-difference-right.svg")];
    }
    &.label-isOtherUser {
      & .label-content {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-difference-middle-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-difference-middle-user-dark.svg")];
      }
      &:before {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-difference-left-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-difference-left-user-dark.svg")];
      }
      &:after {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-difference-right-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-difference-right-user-dark.svg")];
      }
    }
  }

  &.label-correct-score {
    & .label-content {
      @apply bg-[url("../assets/label/tipp-label-result-correct-score-middle.svg")];
      @apply text-white;
    }

    &:before {
      @apply bg-[url("../assets/label/tipp-label-result-correct-score-left.svg")];
    }

    &:after {
      @apply bg-[url("../assets/label/tipp-label-result-correct-score-right.svg")];
    }
    &.label-isOtherUser {
      & .label-content {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-score-middle-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-score-middle-user-dark.svg")];
      }
      &:before {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-score-left-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-score-left-user-dark.svg")];
      }
      &:after {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-score-right-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-score-right-user-dark.svg")];
      }
    }
  }

  &.label-correct-tendency {
    & .label-content {
      @apply bg-[url("../assets/label/tipp-label-result-correct-tendency-middle.svg")];
      @apply text-status-positive-green100;
      @apply px-1.5xs;
    }

    &:before {
      @apply bg-[url("../assets/label/tipp-label-result-correct-tendency-left.svg")];
    }

    &:after {
      @apply bg-[url("../assets/label/tipp-label-result-correct-tendency-right.svg")];
    }
    &.label-isOtherUser {
      & .label-content {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-tendency-middle-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-tendency-middle-user-dark.svg")];
      }
      &:before {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-tendency-left-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-tendency-left-user-dark.svg")];
      }
      &:after {
        @apply bg-[url("../assets/label/user/tipp-label-result-correct-tendency-right-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-correct-tendency-right-user-dark.svg")];
      }
    }
  }

  &.label-incorrect {
    & .label-content {
      @apply bg-[url("../assets/label/tipp-label-result-incorrect-middle.svg")];
      @apply text-status-negative-red200;
    }

    &:before {
      @apply bg-[url("../assets/label/tipp-label-result-incorrect-left.svg")];
    }

    &:after {
      @apply bg-[url("../assets/label/tipp-label-result-incorrect-right.svg")];
    }
    &.label-isOtherUser {
      & .label-content {
        @apply bg-[url("../assets/label/user/tipp-label-result-incorrect-middle-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-incorrect-middle-user-dark.svg")];
      }
      &:before {
        @apply bg-[url("../assets/label/user/tipp-label-result-incorrect-left-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-incorrect-left-user-dark.svg")];
      }
      &:after {
        @apply bg-[url("../assets/label/user/tipp-label-result-incorrect-right-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-incorrect-right-user-dark.svg")];
      }
    }
  }

  &.label-no-tip {
    & .label-content {
      @apply bg-[url("../assets/label/tipp-label-no-tip-middle.svg")];
      @apply text-secondary;
    }

    &:before {
      @apply bg-[url("../assets/label/tipp-label-no-tip-left.svg")];
    }

    &:after {
      @apply bg-[url("../assets/label/tipp-label-no-tip-right.svg")];
    }
    &.label-isOtherUser {
      & .label-content {
        @apply bg-[url("../assets/label/user/tipp-label-no-tip-middle-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-result-incorrect-middle-user-dark.svg")];
      }
      &:before {
        @apply bg-[url("../assets/label/user/tipp-label-no-tip-left-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-no-tip-left-user-dark.svg")];
      }
      &:after {
        @apply bg-[url("../assets/label/user/tipp-label-no-tip-right-user.svg")];
        @apply dark:bg-[url("../assets/label/user/tipp-label-no-tip-right-user-dark.svg")];
      }
    }
  }

  &.label-card {
    & .label-content {
      @apply px-4;
      @apply text-white;
      /* @apply bg-[url("../assets/label/card-header-label-middle.svg")];
      @apply dark:bg-[url("../assets/label/header-label-middle-dark.svg")]; */
      @apply bg-none;
      @apply bg-primary;
    }

    &:before {
      @apply bg-[url("../assets/label/card-header-label-left.svg")];
      @apply dark:bg-[url("../assets/label/card-header-label-left-dark.svg")];
    }

    &:after {
      @apply bg-[url("../assets/label/card-header-label-right.svg")];
      @apply dark:bg-[url("../assets/label/card-header-label-right-dark.svg")];
    }
  }

  &.label-card-attention {
    & .label-content {
      @apply px-4;
      @apply text-status-negative-red100;
      @apply bg-[url("../assets/label/card-header-label-attention-middle.svg")];
    }

    &:before {
      @apply bg-[url("../assets/label/card-header-label-attention-left.svg")];
    }

    &:after {
      @apply bg-[url("../assets/label/card-header-label-attention-right.svg")];
    }
  }

  &.label-side {
    @apply absolute right-0;
    @apply m-0;

    & .label-content {
      @apply pl-0 pr-0.5;
      @apply py-2 xs:pr-1;
      & span {
        @apply -ml-1;
        @apply xs:-ml-1;
        @apply sm:ml-0;
        @apply relative;
        z-index: 1;
        width: 2ch;
      }
    }

    &:after {
      @apply hidden;
    }
  }

  &.label-checkmark {
    @apply absolute right-2 md:right-0;

    & .label-content {
      @apply pl-0 pr-0.5;
      @apply py-2 xs:pr-1;
    }
  }
}
