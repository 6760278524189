@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  @apply flex items-center justify-center;
  @apply p-2;

  &:before {
    content: "";
    display: inline-block;
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left-color: theme("colors.primary.DEFAULT");
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: loader 1.2s linear infinite;
  }
}
