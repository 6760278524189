header {
  @apply bg-primary;
  @apply sticky;
  @apply top-0 left-0 right-0;
  @apply z-50;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  & .header-shadow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
  & .header-wrapper {
    @apply w-full max-w-screen-sm;
    @apply mx-auto;
    @apply px-4 pt-3 pb-2.5;
  }
  &.header-with-sublabel {
    & .header-wrapper {
      @apply pb-1.5;
    }
  }
  & .header-top {
    @apply flex items-center justify-between space-x-4;
    & .header-top-button {
      @apply w-5 h-5;
      @apply flex items-center justify-center;
      @apply relative;
      & .header-top-button-action {
        @apply absolute;
        width: 200%;
        height: 200%;
        @apply flex items-center justify-center;
        @apply cursor-pointer;
        & svg {
          @apply w-5 h-5;
          &.header-top-menu-icon {
            @apply w-6 h-6; /* added "preserveAspectRatio="xMinYMin meet" to the SVG and removed height & width attributes to make the resizing work */
          }
        }
      }
    }
    & .header-top-content {
      @apply flex items-center justify-center space-x-2;
      min-width: 0;
      & .header-top-icon {
        @apply flex;
        @apply w-5 h-5;
        @apply object-contain;
        @apply flex-shrink-0;
      }
      & .header-top-label {
        font-size: 22px;
        line-height: 26px;
        @apply text-white;
        @apply font-bold;
        @apply truncate;
      }
    }
  }
  & .header-divider {
    @apply w-full h-px;
    @apply bg-white;
    @apply mx-auto mt-2.5 mb-1;
    max-width: 200px;
    @media (min-width: theme("screens.xs")) {
      max-width: 263px;
    }
  }
  & .header-bottom {
    @apply flex items-center justify-center;
    @apply space-x-2;
    & .header-bottom-icon {
      @apply w-4 h-4;
      @apply object-contain;
    }
    & .header-bottom-sublabel {
      @apply text-white;
      @apply text-lg;
      @apply font-bold;
      @apply truncate;
    }
  }

  & .header-buttons {
    @apply flex;
    @apply px-2 pt-1.5 pb-1.5;
    @apply w-full max-w-screen-sm;
    @apply mx-auto;
    & .header-button {
      @apply uppercase;
      @apply text-white text-opacity-40 text-center;
      @apply bg-primary;
      @apply border border-secondary;
      @apply flex-1;
      @apply p-1.5;
      @apply first:rounded-l last:rounded-r;
      @apply cursor-pointer;
      @apply transition-all duration-300;
      line-height: 19px;
      &:hover {
        background-color: rgba(var(--secondary-rgb), 0.4);
      }
      &.header-button-active {
        @apply bg-secondary;
        @apply text-opacity-100;
      }
    }
  }
}
