.top-three {
  @apply flex justify-center;
  @apply max-w-screen-xs;
  @apply mx-auto mb-4;

  & .t3-item {
    @apply flex flex-col items-center justify-end;
    @apply w-1/3;

    & .t3-item-trend {
      @apply flex items-center space-x-1;
      @apply mb-1;

      & .t3-item-trend-number {
        @apply text-lg tabular-nums;
      }
    }

    & .t3-item-user {
      @apply flex flex-col items-center;
      @apply w-full;
      @apply mb-3 mt-2;
      background: no-repeat center top / contain
        url("../assets/top-three/user-default.svg");

      & .t3-item-user-image {
        @apply rounded-full;
        @apply w-14 h-14;
        @apply mb-1 -mt-2;
        @apply border border-user-default;
        border-width: 3px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        & .user-image-wrapper {
          @apply w-full h-full;
          @apply border-2 border-user-default-alternative;
          @apply rounded-full;
          & .user-image {
            @apply w-full h-full object-cover object-center;
          }
        }
      }
      & .t3-item-user-points {
        @apply -mb-1.5;
        @apply px-2;
        @apply flex justify-center;
        & .t3-item-user-points-label {
          @apply w-full;
          & .label-content {
          }
        }
      }
    }

    & .t3-item-user-name {
      @apply flex justify-center;
      @apply -mt-1;
      @apply w-full;
      @apply px-2;
      & .t3-item-user-name-label {
        @apply w-full;
        & .label-text {
        }
      }
    }

    &.t3-item-default {
      & .t3-item-user {
        background-image: url("../assets/top-three/user-default.svg");
        & .t3-item-user-image {
          @apply border-user-default;
          & .user-image-wrapper {
            @apply border-user-default-alternative;
          }
        }
      }
    }

    &:not(.t3-item-gold) {
      & .t3-item-trend {
        & .t3-item-trend-number {
          @apply text-sm;
        }
      }
      & .t3-item-user {
        & .t3-item-user-image {
          @apply w-8 h-8;
          @apply border-2;
          & .user-image-wrapper {
            @apply border;
          }
        }
      }
    }

    &.t3-item-gold {
      @apply order-2;
      & .t3-item-user {
        background-image: url("../assets/top-three/user-gold.svg");
        & .t3-item-user-image {
          @apply border-user-gold;
          & .user-image-wrapper {
            @apply border-user-gold-alternative;
          }
        }
      }
    }

    &.t3-item-silver {
      @apply order-1;
      & .t3-item-user {
        background-image: url("../assets/top-three/user-silver.svg");
        & .t3-item-user-image {
          @apply border-user-silver;
          & .user-image-wrapper {
            @apply border-user-silver-alternative;
          }
        }
      }
      & + .t3-item-silver {
        @apply order-3;
      }
    }

    &.t3-item-bronze {
      @apply order-3;
      & .t3-item-user {
        background-image: url("../assets/top-three/user-bronze.svg");
        & .t3-item-user-image {
          @apply border-user-bronze;
          & .user-image-wrapper {
            @apply border-user-bronze-alternative;
          }
        }
      }
    }

    &.t3-item-head-to-head {
      & .t3-item-user {
        background-image: url("../assets/top-three/user-head-to-head.svg");
        & .t3-item-user-image {
          @apply border-user-headToHead;
          & .user-image-wrapper {
            @apply border-user-headToHead-alternative;
          }
        }
      }
    }

    &.t3-item-self {
      & .t3-item-user {
        background-image: url("../assets/top-three/user-self.svg");
        & .t3-item-user-image {
          @apply border-user-self;
          & .user-image-wrapper {
            @apply border-user-self-alternative;
          }
        }
      }
    }
  }
}
